.modal-change-plan{
  .ant-modal-body, .ant-modal-content{
    border-radius: 12px;
  }
  .ant-modal-close{
    color: #fff;
    .ant-modal-close-x{
      font-size: 18px;
    }
  }
}
@media (max-width: 640px) {
  .modal-change-plan{
    .ant-modal-close{
     top: -12px;
    }
    .ant-modal{
      max-width: calc(100vw - 40px);
    }
  }

}
