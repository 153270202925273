/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */
@import '../../../node_modules/ng-zorro-antd/style/index.min.css';
@import '../../../node_modules/ng-zorro-antd/modal/style/index.min.css';
@import '../../../node_modules/ng-zorro-antd/tooltip/style/index.min.css';
@import 'custom/ant/';

@font-face {
  font-family: 'LabGrotesque-Black';
  src: url(./assets/fonts/LabGrotesque-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'LabGrotesque-BlackItalic';
  src: url(./assets/fonts/LabGrotesque-BlackItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'LabGrotesque-Bold';
  src: url(./assets/fonts/LabGrotesque-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'LabGrotesque-BoldItalic';
  src: url(./assets/fonts/LabGrotesque-BoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'LabGrotesque-Italic';
  src: url(./assets/fonts/LabGrotesque-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'LabGrotesque-Light';
  src: url(./assets/fonts/LabGrotesque-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'LabGrotesque-LightItalic';
  src: url(./assets/fonts/LabGrotesque-LightItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'LabGrotesque-Medium';
  src: url(./assets/fonts/LabGrotesque-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'LabGrotesque-MediumItalic';
  src: url(./assets/fonts/LabGrotesque-MediumItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'LabGrotesque-Regular';
  src: url(./assets/fonts/LabGrotesque-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter-Regular';
  src: url(./assets/fonts/Inter-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter-SemiBold';
  src: url(./assets/fonts/Inter-SemiBold.ttf) format('truetype');
}

* {
  color: inherit;
  margin: 0;
}

html,
body {
  margin: 0;
  padding: 0;
  scroll-behavior: auto;
  -webkit-overflow-scrolling: touch;
  font-family: 'Inter-Regular';
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'LabGrotesque-Black';
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

.border-gradient {
  border-radius: 10px;
  background-image: linear-gradient(white, white),
    linear-gradient(to right, #845ef7, #fd5ff4);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

input[type='text'], input[type='email'], input[type='password'] {
  font-family: 'Inter-Regular';
  font-size: 16px;
  font-weight: 400;
}
